import { createTheme } from "@mui/material";




const theme = createTheme({
    palette: {
        primary: {
        main: '#008080',
      },
      secondary: {
        main: '#ffce48',
      },
    },
  })


  export default theme;
